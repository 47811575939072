import React, { useEffect, useState, useRef } from 'react';
import { useNotification } from './NotificationProvider';

const NotificationList = ({ onClose }) => {
  const { notifications } = useNotification(); // Access the global notification context
  const [mouseMoved, setMouseMoved] = useState(false);
  const [lastMouseMoveTime, setLastMouseMoveTime] = useState(Date.now());
  const lastNotificationRef = useRef(null);
  const notificationListRef = useRef(null); // Ref for the notification list container

  // Automatically scroll to the last notification when notifications change
  useEffect(() => {
    if (lastNotificationRef.current) {
      lastNotificationRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [notifications]);

  useEffect(() => {
    let timer;

    const handleMouseMove = () => {
      setMouseMoved(true);
      setLastMouseMoveTime(Date.now());
    };

    const checkMouseActivity = () => {
      const currentTime = Date.now();
      if (currentTime - lastMouseMoveTime > 3000) {
        setMouseMoved(false);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    timer = setInterval(() => {
      checkMouseActivity();
      if (!mouseMoved) {
        onClose();
      }
    }, 5000);

    return () => {
      clearInterval(timer);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [mouseMoved, lastMouseMoveTime, onClose]);

  // Handle click outside to dismiss
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationListRef.current && !notificationListRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      ref={notificationListRef}
      className="position-absolute top-50 end-0 p-3"
      style={{ zIndex: 1000 }}
    >
      <div className="card shadow-sm">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Notifications</h5>
          <button onClick={onClose} className="btn-close" aria-label="Close"></button>
        </div>
        <div className="card-body" style={{ maxHeight: '200px', overflowY: 'auto' }}>
          <ul className="list-group">
            {notifications?.length > 0 ? (
              notifications.map((notification, index) => (
                <li
                key={notification.id}
                className="list-group-item notification-item"
                ref={index === notifications.length - 1 ? lastNotificationRef : null}
              >
                <span className="notification-text">{notification.message}</span>
                </li>
              ))
            ) : (
              <li className="list-group-item"
                style={{
                  width: '300px',
                  maxWidth: '300px', // Set the desired fixed width
                  whiteSpace: 'nowrap', // Prevent text from wrapping to the next line
                  overflow: 'hidden', // Hide overflowed content
                  textOverflow: 'ellipsis', // Add ellipses for overflowed content
                }}
              >
                No notifications
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NotificationList;
