import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNotification } from '../../components/NotificationProvider';
import { Alert, Dropdown } from "react-bootstrap";
import { BiReset } from "react-icons/bi";
import "../../styles/monitorAnalytics.scss";
import { DownloadFile } from '../../components/downloadFile';

const FileDownloader = (props) => {
  const [task1Id, setTask1Id] = useState(null);
  const [task2Id, setTask2Id] = useState(null);
  const [task1Status, setTask1Status] = useState('Idle');
  const [task2Status, setTask2Status] = useState('Idle');
  const {notify } = useNotification(); // Use the custom hook

  // Effect to handle side effects for notifications

  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);

  const [logId, setLogId] = useState(null);

  /* FILTER VARIABLES */
  const [weekFilters, setWeekFilters] = useState(null);
  const [weekSelectedAll, setWeekSelectedAll] = useState(false);
  const [yearFilters, setYearFilters] = useState([]);
  const [fiscalYearFilters, setFiscalYearFilters] = useState([]);

  const [monthFilters, setMonthFilters] = useState([]);
  const [monthSelectedAll, setMonthSelectedAll] = useState(false);
  const [monthsToShow, setMonthsToShow] = useState([]);

  /*FILTER SELECTED VARIABLES */
  const [weeksToShow, setWeeksToShow] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("January");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedYearAllData, setSelectedYearAllData] = useState("");

  // CSV DOWNLOAD
  const [sosFileDownloading, setSOSFileDownloading] = useState(false);
  const [rspFileDownloading, setRSPFileDownloading] = useState(false);
  const [flyerFileDownloading, setflyerFileDownloading] = useState(false);
  const [secDisplayFileLoading, setSecDisplayFileLoading] = useState(false);
  const [monitorDataLoading, setMonitorDataLoading] = useState(false);
  const [rspTrackerLoading, setRSPTrackerLoading] = useState(false);
  const [planogramFileLoading, setPlanogramFileLoading] = useState(false);
  const [osaFileLoading, setOSAFileLoading] = useState(false);
  const [fullStoreLevelDataLoading, setFullStoreLevelDataLoading] = useState(false);
  const [monitorDataButtonClicked, setMonitorDataButtonClicked] =
    useState(false);
  
  

  const timeOut = () => {
    const timeId = setTimeout(() => {
      setAlertSuccess(null);
      setAlertError(null);
    }, 2000);

    return () => {
      clearTimeout(timeId);
    };
  };

  useEffect(() => {
    setIsLoading(true);
    setAlertError(null);
    setWeekFilters(null);
    const fetchData = async () => {
      await axios({
        method: "post",
        url: "/api/filtervalues",
        headers: { "Content-Type": "application/json" },
        data: { category: [] },
        timeout: 180000,
      })
        .then((response) => {
          setIsLoading(false);
          const responseData = response.data;
          setWeekFilters(responseData.Week.map(String));
          setFiscalYearFilters(responseData?.fiscalYear);
        })
        .catch((error) => {
          console.log("Filter Values Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
          timeOut();
        });
    };
    const getMonthFilters = async () => {
      await axios({
        method: "post",
        url: "/api/filter_values_on_shelf",
        headers: { "Content-Type": "application/json" },
        data: { category: [] },
      })
        .then((response) => {
          setIsLoading(false);
          const responseData = response.data;
          setMonthFilters(responseData.month);
          setYearFilters(responseData?.year);
        })
        .catch((error) => {
          console.log("Filter Values Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
          timeOut();
        });
    };
    const userLogActivity = async () => {
      setAlertError("");

      await axios({
        method: "post",
        url: "/api/LogUserActivity",
        data: {
          page: "Reports Download Activity Started",
          username: props?.currentUser?.user,
        },
      })
        .then((response) => {
          setLogId(response?.data?.LogId);
        })
        .catch(function (error) {
          console.log("Get Databases Api: ", error);
          setAlertError("something went wrong");
          notify(error+"something went wrong")
        });
    };
    fetchData();
    getMonthFilters();
    userLogActivity();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (weekFilters) {
      let filterArray = [];

      filterArray.push(
        weekFilters.map((week) => {
          return week;
        })
      );

      setWeeksToShow(filterArray[0]);
      setWeekSelectedAll(true);
    }
  }, [weekFilters]);

  const selectAll = (e, flag) => {
    if (flag === "weeks") {
      if (!e.target.checked) {
        setWeeksToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          weekFilters.map((week) => {
            return week;
          })
        );

        setWeeksToShow(filterArray[0]);
      }

      setWeekSelectedAll(e.target.checked);
    } else if (flag === "month") {
      if (!e.target.checked) {
        setMonthsToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          monthFilters.map((week) => {
            return week;
          })
        );

        setMonthsToShow(filterArray[0]);
      }

      setMonthSelectedAll(e.target.checked);
    }
  };

  const checkBoxFilterHandle = (e, flag) => {
    const { checked, value } = e.target;

    if (flag === "week") {
      if (checked) {
        const checkedValue = weeksToShow.includes(value);
        if (!checkedValue) {
          const tempweekFilters = [...weeksToShow, value];
          setWeeksToShow(tempweekFilters);

          if (tempweekFilters.length === weekFilters.length) {
            setWeekSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = weeksToShow.filter((element) => {
          return element !== value;
        });

        setWeeksToShow(uncheckedValue);
        setWeekSelectedAll(false);
      }
    } else if (flag === "month") {
      // const checkedValue = weeksToShow.includes(value);
      if (checked) {
        const checkedValue = monthsToShow.includes(value);
        if (!checkedValue) {
          const tempmonthFilters = [...monthsToShow, value];
          setMonthsToShow(tempmonthFilters);

          if (tempmonthFilters.length === monthFilters.length) {
            setMonthSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = monthsToShow.filter((element) => {
          return element !== value;
        });

        setMonthsToShow(uncheckedValue);
        setMonthSelectedAll(false);
      }
    }
  };
  const recordLogActivity = async (url) => {
    setAlertError("");
    let parts = url.split("/");
    let report_name = parts[parts.length - 1];
    let payload;
    if (report_name === "flyerdata") {
      payload = {
        ReportName: report_name,
        year: selectedYear,
        month: monthsToShow,
        week: weeksToShow,
        Downloaded: 1,
        LogId: logId,
        username: props?.currentUser?.user,
      };
    } else {
      payload = {
        ReportName: report_name,
        year: selectedYearAllData,
        month: monthsToShow,
        week: weeksToShow,
        Downloaded: 1,
        LogId: logId,
        username: props?.currentUser?.user,
      };
    }
    await axios({
      method: "post",
      url: "/api/LogUserReportActivity",
      data: payload,
    })
      .then((response) => {
      })
      .catch(function (error) {
        console.log("Get Databases Api: ", error);
        setAlertError("log Activity error");
      });
  };

  const handleGenerate = async (apiUrl, filePath, setFileDownloading) => {
    setFileDownloading(true);
    let payload;
    if (apiUrl === "/api/generate_flyer_data") {
      payload = {
        Weeks: weeksToShow,
        year: selectedYear,
        flag : 'template_data',
        file_path:filePath
      };
    } else {
      payload = {
        month: monthsToShow,
        year: selectedYearAllData,
        flag : 'template_data',
        file_path:filePath
      };
    }
      await axios({
        method: "post",
        url: apiUrl,
        data: payload,
      })
        .then((response) => {
          const { task_id,file_path } = response.data;
          // Start polling to download the file once ready
          DownloadFile(task_id, file_path,setFileDownloading,notify,"xlsx");
        })
        .catch(function (error) {
          notify('Error in generating file.');
          console.log(error);
        });
  };

  return (
    <div className="container-fluid monitor_performance position-relative">
      <h2 className="heading_sm_center">Download Templates</h2>
      {alertError && <Alert variant="danger">{alertError}</Alert>}
      {alertSuccess && <Alert variant="success">{alertSuccess}</Alert>}
      {/* <div className="row">
                <div className="col-md-4" style={{ margin: '0 0 10px 0' }}>
                    
                </div>
            </div> */}
      <div className="row justify-content-between">
        <div className="col-md-4 custom_card p-4">
          <label htmlFor="" className="table_label text-center my-0">
            Weekly Data
          </label>
          <div className="template_buttons">
            <div className="row w-100 justify-content-start template_filters">
              <div className="filter-dropdowns col-6 m-0 p-0">
                <h6>Select Year</h6>
                <Dropdown
                  className={`${
                    !selectedYear ? "dropDown_disabled" : "dropdown_selected"
                  }`}
                >
                  <Dropdown.Toggle variant="secondary">
                    {selectedYear ? selectedYear : "Year"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {fiscalYearFilters?.map((year, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={(event) => setSelectedYear(event.target.text)}
                      >
                        {year}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="filter-dropdowns col-6 m-0 p-0">
                <h6>Select Week</h6>
                <Dropdown
                  className={`${
                    !(weeksToShow.length > 0)
                      ? "dropDown_disabled"
                      : "dropdown_selected"
                  }`}
                >
                  <Dropdown.Toggle variant="secondary">
                    {isLoading ? (
                      <div
                        className="spinner-border text-light"
                        role="status"
                        style={{ zIndex: "2", width: "20px", height: "20px" }}
                      ></div>
                    ) : (
                      "Week"
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div
                      className="p-3 d-flex flex-column"
                      style={{ gap: "5px" }}
                    >
                      <div className="d-flex" style={{ gap: "0 8px" }}>
                        <input
                          type="checkbox"
                          name="selectAll"
                          checked={weekSelectedAll}
                          onChange={(e) => selectAll(e, "weeks")}
                        />
                        <label htmlFor="selectAll">Select All</label>
                      </div>

                      {weekFilters?.map((week, index) => (
                        <div
                          key={index}
                          className="d-flex"
                          style={{ gap: "0 10px" }}
                        >
                          <input
                            type="checkbox"
                            value={week}
                            onChange={(e) => checkBoxFilterHandle(e, "week")}
                            checked={weeksToShow.includes(week) ? true : false}
                          />
                          <label style={{ fontSize: "12px" }}>{week}</label>
                        </div>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="row w-100 justify-content-start template_filters">
              <div className="col-6 col-md-4 m-0 text_sm_center p-0">
                <button
                onClick={() => handleGenerate("/api/generate_flyer_data", "flyer.xlsx", setflyerFileDownloading)}
              
                  className="btn btn-primary"
                  style={{ width: "120px", height: "32px" }}
                  disabled={(weeksToShow.length < 1 || !selectedYear)}
                >
                  {flyerFileDownloading? (
                    <div
                      className="spinner-border text-info"
                      role="status"
                      style={{ zIndex: "2", width: "20px", height: "20px" }}
                    ></div>
                  ) : (
                    "Flyer Data"
                  )}
                </button>
              </div>
              <div className="col-6 col-md-4 m-0 text_sm_center p-0">
              
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 custom_card p-4">
          <label htmlFor="" className="table_label text-center my-0">
            All Data
          </label>
          {/* <div className="row w-100 template_buttons justify-content-start">
            <div className="col-3 m-0">
              <div className="filter-dropdowns">
                <Dropdown
                  className={`${
                    !selectedMonth ? "dropDown_disabled" : "dropdown_selected"
                  }`}
                >
                  <Dropdown.Toggle variant="secondary">
                    {selectedMonth ? selectedMonth : "Month"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {monthFilters?.map((month, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={(event) => setSelectedMonth(event.target.text)}
                      >
                        {month}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div> */}
          <div className="template_buttons">
            <div className="row w-100 justify-content-start template_filters">
              <div className="filter-dropdowns col-6 col-md-4 m-0 text-sm-cen p-0">
                <h6>Select Year</h6>
                <Dropdown
                  className={`m-0 ${
                    !selectedYearAllData
                      ? "dropDown_disabled"
                      : "dropdown_selected"
                  }`}
                >
                  <Dropdown.Toggle variant="secondary">
                    {selectedYearAllData ? selectedYearAllData : "Year"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {yearFilters?.map((year, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={(event) =>
                          setSelectedYearAllData(event.target.text)
                        }
                      >
                        {year}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="filter-dropdowns col-6 col-md-4 m-0 text-sm-cen p-0">
                <h6>Select Month</h6>
                <Dropdown
                  className={`${
                    !(monthsToShow.length > 0)
                      ? "dropDown_disabled"
                      : "dropdown_selected"
                  }`}
                >
                  <Dropdown.Toggle variant="secondary">Month</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div
                      className="p-3 d-flex flex-column"
                      style={{ gap: "5px" }}
                    >
                      <div className="d-flex" style={{ gap: "0 8px" }}>
                        <input
                          type="checkbox"
                          name="selectAll"
                          checked={monthSelectedAll}
                          onChange={(e) => selectAll(e, "month")}
                        />
                        <label htmlFor="selectAll">Select All</label>
                      </div>

                      {monthFilters?.map((week, index) => (
                        <div
                          key={index}
                          className="d-flex"
                          style={{ gap: "0 10px" }}
                        >
                          <input
                            type="checkbox"
                            value={week}
                            onChange={(e) => checkBoxFilterHandle(e, "month")}
                            checked={monthsToShow.includes(week) ? true : false}
                          />
                          <label style={{ fontSize: "12px" }}>{week}</label>
                        </div>
                      ))}
                    </div>
                  </Dropdown.Menu>
              
                </Dropdown>
              </div>
            
            </div>
            <div className="row w-100 justify-content-start template_filters">
              <div className="col-6 col-md-4 m-0 text_sm_center p-0">
                  <button
                    onClick={() => handleGenerate("/api/generate", "SOS_Template_file.xlsx", setRSPFileDownloading)}
                  
                    className="btn btn-primary"
                    style={{ width: "120px", height: "32px" }}
                  disabled={(monthsToShow.length < 1 || !selectedYearAllData)}

                  >
                    {rspFileDownloading  ? (
                      <div
                        className="spinner-border text-info"
                        role="status"
                        style={{ zIndex: "2", width: "20px", height: "20px" }}
                      ></div>
                    ) : (
                      "SOS Template"
                    )}
                  </button>
                </div>

                <div className="col-6 col-md-4 m-0 text_sm_center p-0">
                  <button
                  onClick={() => handleGenerate("/api/generate", "Sec_Display_template.xlsx", setSecDisplayFileLoading)}
                
                    className="btn btn-primary"
                    style={{ width: "120px", height: "32px" }}
                    disabled={(monthsToShow.length < 1 || !selectedYearAllData)}
                  >
                    {secDisplayFileLoading? (
                      <div
                        className="spinner-border text-info"
                        role="status"
                        style={{ zIndex: "2", width: "20px", height: "20px" }}
                      ></div>
                    ) : (
                     "Sec Display"
                    )}
                  </button>
                </div>

                <div className="col-6 col-md-4 m-0 text_sm_center p-0">
                  <button
                  onClick={() => handleGenerate("/api/generate", "all_values.xlsx", setMonitorDataLoading)}
                
                    className="btn btn-primary"
                    style={{ width: "120px", height: "32px" }}
                    disabled={(monthsToShow.length < 1 || !selectedYearAllData)}
                  >
                    {monitorDataLoading? (
                      <div
                        className="spinner-border text-info"
                        role="status"
                        style={{ zIndex: "2", width: "20px", height: "20px" }}
                      ></div>
                    ) : (
                      "Monitor Data"
                    )}
                  </button>
               </div>

               <div className="col-6 col-md-4 m-0 text_sm_center p-0">
                  <button
                  onClick={() => handleGenerate("/api/generate", "rsp.xlsx", setRSPTrackerLoading)}
                
                    className="btn btn-primary"
                    style={{ width: "120px", height: "32px" }}
                    disabled={(monthsToShow.length < 1 || !selectedYearAllData)}
                  >
                    {rspTrackerLoading? (
                      <div
                        className="spinner-border text-info"
                        role="status"
                        style={{ zIndex: "2", width: "20px", height: "20px" }}
                      ></div>
                    ) : (
                      "RSP Tracker"
                    )}
                  </button>
                </div>

                <div className="col-6 col-md-4 m-0 text_sm_center p-0">
                    <button
                    onClick={() => handleGenerate("/api/generate", "Planogram_file.xlsx", setPlanogramFileLoading)}
                  
                      className="btn btn-primary"
                      style={{ width: "120px", height: "32px" }}
                      disabled={(monthsToShow.length < 1 || !selectedYearAllData)}
                    >
                      {planogramFileLoading? (
                        <div
                          className="spinner-border text-info"
                          role="status"
                          style={{ zIndex: "2", width: "20px", height: "20px" }}
                        ></div>
                      ) : (
                        "Planogram File"
                      )}
                    </button>
                </div>

              <div className="col-6 col-md-4 m-0 text_sm_center p-0">
                  <button
                  onClick={() => handleGenerate("/api/generate", "OSA.xlsx", setOSAFileLoading)}
                
                    className="btn btn-primary"
                    style={{ width: "120px", height: "32px" }}
                    disabled={(monthsToShow.length < 1 || !selectedYearAllData)}
                  >
                    {osaFileLoading? (
                      <div
                        className="spinner-border text-info"
                        role="status"
                        style={{ zIndex: "2", width: "20px", height: "20px" }}
                      ></div>
                    ) : (
                      "OSA File"
                    )}
                  </button>
              </div>

              <div className="col-6 col-md-4 m-0 text_sm_center p-0">
                  <button
                  onClick={() => handleGenerate("/api/generate", "full_store_level_report.xlsx", setFullStoreLevelDataLoading)}
                
                    className="btn btn-primary"
                    style={{ width: "120px", height: "32px" }}
                    disabled={(monthsToShow.length < 1 || !selectedYearAllData)}
                  >
                    {fullStoreLevelDataLoading? (
                      <div
                        className="spinner-border text-info"
                        role="status"
                        style={{ zIndex: "2", width: "20px", height: "20px" }}
                      ></div>
                    ) : (
                        "Store Report"
                    )}
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  );
};
export default FileDownloader;
