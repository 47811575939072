import axios from "axios";

export const DownloadFile = (taskId, filePath, setFileDownloading,notify, fileType = "xlsx") => {
    // Notify the user that the file is processing
    notify(taskId, false, `${filePath} is Processing...`);
  
    const intervalId = setInterval(async () => {
      try {
        const response = await axios.get(`/api/result/${taskId}?filePath=${encodeURIComponent(filePath)}`, {
          responseType: 'blob', // Set response type to 'blob'
        });
  
        if (response?.status === 200) {
          setFileDownloading(false);
          clearInterval(intervalId);
  
          const successMessage = `The file ${filePath} has been successfully downloaded. Please check your Downloads folder.`;
          notify(taskId, true, successMessage);
  
          const blob = new Blob([response.data], {
            type: getMimeType(fileType),  // Dynamically set MIME type based on the file type
          });
  
          const blobURL = window.URL.createObjectURL(blob);
  
          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = filePath;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            getMimeType(fileType), 
            anchor.download, 
            anchor.href,
          ].join(":");
          anchor.click();
  
          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 100);
        } else if (response?.status === 202) {
          // Update the existing notification for pending state
          notify(taskId, false, `${filePath} is Processing...`);
        } else {
          setFileDownloading(false);
          clearInterval(intervalId);
          notify(taskId, false, `${filePath} Error in file generation.`);
        }
      } catch (error) {
        setFileDownloading(false);
        clearInterval(intervalId);
        notify(taskId, false, `${filePath} There is a problem in the server. Please contact site admin.`);
      }
    }, 5000); // Polling every 5 seconds
  };
  
  // Utility function to return the correct MIME type based on the file type
  const getMimeType = (fileType) => {
    switch (fileType.toLowerCase()) {
      case 'pdf':
        return 'application/pdf';
      case 'image':
        return 'image/jpeg';  // Can be extended to handle other image formats like PNG, etc.
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'zip':
        return "application/zip"
      default:
        return 'application/octet-stream'; // Default for unknown file types
    }
  };
  