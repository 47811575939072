import React, { useEffect, useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, gridClasses } from '@mui/x-data-grid';
import { RenderCellExpand } from './DataTableCellExpand';
import { BiCut } from 'react-icons/bi';
import { BsImageFill } from 'react-icons/bs';
import '../styles/featureData.scss';
import { Link } from 'react-router-dom';

const DynamicDataTable = ({
  columnDetails,
  columnHeaders,
  rows,
  isLoading,
  pageSize,
  page,
  setPageSize,
  setPage,
  rowCount,
  rowID,
  serverPaginationMode = true
}) => {
  const [columns, setColumns] = useState([]);


  // const queryOptions = React.useMemo(
  //   () => ({
  //     page,
  //     pageSize,
  //   }),
  //   [page, pageSize],
  // );

  // const { data, pageInfo } = queryOptions;

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  //   const [rowCountState, setRowCountState] =useState(
  //     pageInfo?.totalRowCount || 0,
  //   );
  //  useEffect(() => {
  //     setRowCountState((prevRowCountState) =>
  //       pageInfo?.totalRowCount !== undefined
  //         ? pageInfo?.totalRowCount
  //         : prevRowCountState,
  //     );
  //   }, [pageInfo?.totalRowCount, setRowCountState]);

  const gsBuckerURL = "//storage.googleapis.com/cloroximages";
  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => (
    {
      [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
        '&.Mui-selected': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
          '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
            ),
            '@media (hover: none)': {
              backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
              )
            }
          }
        }
      }
    }));

  useEffect(() => {
    const actualColumnHeaders = Object.keys(columnDetails);
    const dataTypes = Object.values(columnDetails)?.map(column => column.type);
    const flex = Object.values(columnDetails).map(column => column.flex);
    let tempColumns = [];

    for (let i = 0; i < actualColumnHeaders.length; i++) {
      const values = rows.map(keys => keys[actualColumnHeaders[i]]);
      const isValueString = values.every(value => isNaN(value));

      if (isNaN(values[i])) {

        tempColumns.push({
          field: actualColumnHeaders[i],
          headerName: columnHeaders[i],
          type: 'string',
          // width: widths[i],
          flex: flex[i],
          filterable: true,
          renderCell: RenderCellExpand
        });
      }
      else {
        tempColumns.push({
          field: actualColumnHeaders[i],
          headerName: columnHeaders[i],
          type: 'number',
          // width: widths[i],
          flex: flex[i],
          filterable: true,
          renderCell: RenderCellExpand
        });
      }
    }


    setColumns(tempColumns);

  }, [columnDetails, columnHeaders, rows]);

  console.log(rowCount, 'jflsjfl')

  function CustomGridToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="datagrid">
      <StripedDataGrid
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        // columnBuffer={2} 
        rowHeight ={25}
        // rowsPerPageOptions={[5]}
        pagination
        page={page}
        pageSize={pageSize}
        paginationMode={serverPaginationMode?"server":"client"}
        loading={isLoading}
        density="compact"
        disableExtendRowFullWidth={true}
        disableSelectionOnClick={false}
        getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
        components={{ Toolbar: CustomGridToolbar }}
        getRowId = {(row) => row[rowID]}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
      />
    </div>
  );
}

export default DynamicDataTable;