// NotificationContext.js
import React, { createContext, useState, useContext } from 'react';

// Create a context
const NotificationContext = createContext();

// Create a provider component
export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const notify = (taskId, downloaded, message) => {
    setNotifications((prevNotifications) => {
      // Check if the notification already exists for the taskId
      const existingNotificationIndex = prevNotifications.findIndex(
        (notification) => notification.taskId === taskId
      );

      if (existingNotificationIndex !== -1 && !downloaded) {
        // Update existing notification
        const updatedNotifications = [...prevNotifications];
        updatedNotifications[existingNotificationIndex] = {
          ...updatedNotifications[existingNotificationIndex],
          message,
        };
        return updatedNotifications;
      }

      // Add new notification
      return [
        ...prevNotifications,
        { id: Date.now(), taskId, message },
      ];
    });
  };

  const clearNotifications = () => {
    setNotifications([]);
  };

  return (
    <NotificationContext.Provider
      value={{ notifications, notify, clearNotifications }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

// Custom hook to use the notification context
export const useNotification = () => {
  return useContext(NotificationContext);
};
