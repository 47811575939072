import React, { useState, useEffect } from 'react';
import { FaBell } from 'react-icons/fa'; // Import the bell icon
import NotificationList from './NotificationList';
import { useNotification } from './NotificationProvider';

const BellIcon = () => {
  const { notifications } = useNotification(); // Use the custom hook to access notifications
  const [isOpen, setIsOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0); // State to track unread count
  const [lastSeenCount, setLastSeenCount] = useState(0); // State to track notifications seen on last open

  const toggleSidebar = () => {
    setIsOpen((prev) => {
      if (!prev) {
        // If the sidebar is opening, set last seen count to current notifications length
        setLastSeenCount(notifications.length);
        setUnreadCount(0); // Reset unread count to 0 when opening
      }
      return !prev;
    });
  };

  useEffect(() => {
    if (!isOpen) {
      // Calculate unread count only when sidebar is closed
      const newUnreadCount = notifications.length - lastSeenCount;
      setUnreadCount(Math.max(0, newUnreadCount)); // Ensure the count doesn't go negative
    }
  }, [notifications, isOpen, lastSeenCount]); // Dependency on notifications, isOpen, and lastSeenCount

  return (
    <>
      <div onClick={toggleSidebar} style={bellIconStyle}>
        <FaBell style={{ fill: '#ffffff' }} />
        {unreadCount > 0 && <span style={badgeStyle}>{unreadCount}</span>}
      </div>
      {isOpen && <NotificationList notifications={notifications} onClose={() => setIsOpen(false)} />}
    </>
  );
};

const bellIconStyle = {
  cursor: 'pointer',
  fontSize: '24px',
  position: 'relative',
};

const badgeStyle = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '-5px',
  right: '-10px',
  backgroundColor: 'red',
  color: 'white',
  borderRadius: '50%',
  padding: '5px',
  fontSize: '10px',
  fontWeight: 'bold',
  height: '20px',
  width: '20px',
};

export default BellIcon;
