import React, { useState, useEffect, useRef } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
  SubMenu,
} from "react-pro-sidebar";
import { TbReportSearch } from "react-icons/tb";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { IoMdAnalytics } from "react-icons/io";
import SidePanel from "./SidePanel";
import { useNavigate } from "react-router-dom";
import { CiLogout } from "react-icons/ci";
import Logo from "../assets/clorox.png";
import Avatar from "../assets/avatar.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/sidebar.css";
import { BiPhotoAlbum } from "react-icons/bi";
import BellIcon from "./BellIcon";

const SideBar = ({ currentUser, component, logOut }) => {
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 992px)").matches
  );
  const [isFeatureDataSubMenuOpen, setIsFeatureDataSubMenuOpen] =
    useState(true);
  const [isMonitorAnalyticsSubMenuOpen, setIsMonitorAnalyticsSubMenuOpen] =
    useState(false);
    const [isPhotoHubSubMenuOpen, setIsPhotoHubSubMenuOpen] =
    useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const { collapseSidebar, collapsed } = useProSidebar();
  const profileDropDownRef = useRef(null);
  const navigate = useNavigate();
  useOutsideAlerter(profileDropDownRef);

  useEffect(() => {
    window
      .matchMedia("(max-width: 992px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref?.current && !ref?.current?.contains(event.target)) {
          setOpenProfileMenu(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const tabHandler = (tab, flag) => {
    if (flag === "mobile_view") {
      toggleSidePanel();
      navigate(tab);
    } else {
      navigate(tab);
    }
  };

  const handleFeatureDataSubMenuState = () => {
    setIsFeatureDataSubMenuOpen(!isFeatureDataSubMenuOpen);
    setIsMonitorAnalyticsSubMenuOpen(false);
    setIsPhotoHubSubMenuOpen(false)
  };

  const handleMonitorAnalyticsSubMenuState = () => {
    setIsFeatureDataSubMenuOpen(false);
    setIsMonitorAnalyticsSubMenuOpen(!isMonitorAnalyticsSubMenuOpen);
    setIsPhotoHubSubMenuOpen(false)
  };

  const handlePhotoHubSubMenuState = () => {
    setIsPhotoHubSubMenuOpen(!isPhotoHubSubMenuOpen)
    setIsFeatureDataSubMenuOpen(false);
    setIsMonitorAnalyticsSubMenuOpen(false);
  };
  const toggleProfileMenu = () => {
    setOpenProfileMenu(!openProfileMenu);
  };

  const toggleSidePanel = () => {
    setOpenSidePanel(!openSidePanel);
  };

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div style={{ display: "flex" }}>
      {matches ? (
        <SidePanel
          openSidePanel={openSidePanel}
          setOpenSidePanel={setOpenSidePanel}
          tabHandler={tabHandler}
          activeTab={window.location.pathname}
        />
      ) : (
        <Sidebar className="sidebar">
          <div className="logo_section">
            <img
              src={Logo}
              alt="Clorox"
              className="logo"
              onClick={handleClick}
            />
          </div>

          <Menu style={{ marginTop: "30px" }}>
            <SubMenu
              icon={<TbReportSearch />}
              label="Feature Data"
              open={isFeatureDataSubMenuOpen}
              onOpenChange={() => handleFeatureDataSubMenuState()}
            >
              <MenuItem
                active={
                  window.location.pathname === "/" ||
                  window.location.pathname === "/feature-data/clorox-data"
                }
                onClick={() => tabHandler("/feature-data/clorox-data")}
              >
                Clorox Data
              </MenuItem>
              <MenuItem
                active={
                  window.location.pathname ===
                  "/feature-data/clorox-and-competition-data"
                }
                onClick={() =>
                  tabHandler("/feature-data/clorox-and-competition-data")
                }
              >
                Clorox & Competition Data
              </MenuItem>
              <MenuItem
                active={window.location.pathname === "/feature-data/table-view"}
                onClick={() => tabHandler("/feature-data/table-view")}
              >
                Table View
              </MenuItem>
            </SubMenu>
            <SubMenu
              icon={<BiPhotoAlbum />}
              label="Photo Hub"
              open={isPhotoHubSubMenuOpen}
              onOpenChange={() => handlePhotoHubSubMenuState()}
            >
              <MenuItem
                active={
                  window.location.pathname === "/photo-hub/photos"
                }
                onClick={() => tabHandler("/photo-hub/photos")}
              >
                Photos
              </MenuItem>
            </SubMenu>
            <SubMenu
              icon={<IoMdAnalytics />}
              label="Monitor Analytics"
              open={isMonitorAnalyticsSubMenuOpen}
              onOpenChange={() => handleMonitorAnalyticsSubMenuState()}
            >
              <MenuItem
                active={
                  window.location.pathname ===
                  "/monitor-analytics/monitor-performance"
                }
                onClick={() =>
                  tabHandler("/monitor-analytics/monitor-performance")
                }
              >
                Monitor Performance
              </MenuItem>
              <MenuItem
                active={
                  window.location.pathname ===
                  "/monitor-analytics/monitor-route"
                }
                onClick={() => tabHandler("/monitor-analytics/monitor-route")}
              >
                Monitor Route
              </MenuItem>
              <MenuItem
                active={
                  window.location.pathname ===
                  "/monitor-analytics/target-vs-actual"
                }
                onClick={() =>
                  tabHandler("/monitor-analytics/target-vs-actual")
                }
              >
                Target Vs Actual
              </MenuItem>
              <MenuItem
                active={
                  window.location.pathname ===
                  "/monitor-analytics/target-vs-actual-per-day"
                }
                onClick={() =>
                  tabHandler("/monitor-analytics/target-vs-actual-per-day")
                }
              >
                Target Vs Actual Per Day
              </MenuItem>
              <MenuItem
                active={
                  window.location.pathname === "/monitor-analytics/templates"
                }
                onClick={() => tabHandler("/monitor-analytics/templates")}
              >
                Templates
              </MenuItem>
              
              {/* <MenuItem
                active={
                  window.location.pathname ===
                  "/monitor-analytics/image-download"
                }
                onClick={() => tabHandler("/monitor-analytics/image-download")}
              >
                Image Download
              </MenuItem> */}
              <MenuItem
                active={
                  window.location.pathname === "/monitor-analytics/availability"
                }
                onClick={() => tabHandler("/monitor-analytics/availability")}
              >
                OnShelf Availability
              </MenuItem>
              <MenuItem
                active={
                  window.location.pathname ===
                  "/monitor-analytics/share-of-shelf"
                }
                onClick={() => tabHandler("/monitor-analytics/share-of-shelf")}
              >
                Share Of Shelf
              </MenuItem>
              <MenuItem
                active={
                  window.location.pathname === "/monitor-analytics/planogram"
                }
                onClick={() => tabHandler("/monitor-analytics/planogram")}
              >
                Planogram
              </MenuItem>
              <MenuItem
                active={
                  window.location.pathname ===
                  "/monitor-analytics/secondary-display"
                }
                onClick={() =>
                  tabHandler("/monitor-analytics/secondary-display")
                }
              >
                Secondary Display
              </MenuItem>
              <MenuItem
                active={
                  window.location.pathname === "/monitor-analytics/summary"
                }
                onClick={() => tabHandler("/monitor-analytics/summary")}
              >
                Summary
              </MenuItem>
              <MenuItem
                active={window.location.pathname === "/monitor-analytics/RSP"}
                onClick={() => tabHandler("/monitor-analytics/RSP")}
              >
                RSP
              </MenuItem>
            </SubMenu>
          </Menu>
        </Sidebar>
      )}

      <div className={`content_section ${collapsed ? "collapsed" : ""}`}>
        {matches && openSidePanel && <div className="overlay"></div>}
        <div className={`top_header ${collapsed ? "collapsed" : ""}`}>
          {matches ? (
            <>
              <button className="openbtn" onClick={toggleSidePanel}>
                ☰
              </button>

              <div className="logo_section">
                <img
                  src={Logo}
                  alt="Clorox"
                  className="logo"
                  onClick={handleClick}
                />
              </div>
            </>
          ) : (
            <button
              className="btn text-white menu-button m-0 d-flex align-items-center"
              onClick={() => collapseSidebar()}
            >
              {collapsed ? (
                <AiOutlineMenuUnfold className="header-icon" />
              ) : (
                <AiOutlineMenuFold className="header-icon" />
              )}
              Menu
            </button>
          )}

          {currentUser && (
            <div className="profile-container">
              <BellIcon />
              {!matches && (
                <div className="user-details">
                  <span>{currentUser?.user}</span>
                  <small>{currentUser?.role}</small>
                </div>
              )}

              <div className="action" ref={profileDropDownRef}>
                <div className="profile" onClick={toggleProfileMenu}>
                  <img src={Avatar} alt="User" />
                </div>
                <div className={`menu ${openProfileMenu ? "active" : ""}`}>
                  <h3>
                    {currentUser?.user}
                    <br />
                    <span>{currentUser?.role}</span>
                  </h3>
                  <ul>
                    <li>
                      <CiLogout className="icon" />
                      <a
                        href="#/"
                        onClick={(event) => {
                          event.preventDefault();
                          logOut();
                        }}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="content_inner">{component ? component : ""}</div>
      </div>
    </div>
  );
};

export default SideBar;
