import React, { useState, useEffect } from 'react';
import { setRole } from '../../actions/userRole';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus, EventType } from '@azure/msal-browser';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, Navigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert, Button } from 'react-bootstrap';
import axios from 'axios';
import Logo from '../../assets/clorox.png';
import 'semantic-ui-css/semantic.min.css';
import '../../styles/login.css';

const Login = ({ instance, isAuthenticated, currentUser, setCurrentUser }) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const [alertError, setAlertError] = useState(localStorage.getItem("loginFailure") || null);
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /* AUTHORIZATION VARIABLES */
    const [isLoggedOut, setIsLoggedOut] = useState(localStorage.getItem("IsLoggedOut"));
    const { accounts, inProgress } = useMsal();

    /* FORM VARIABLES */
    const [action, setAction] = useState("Simple Log-In");
    
    useEffect(() =>
    {
        const callbackId = instance?.addEventCallback((event) => 
        {        
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) 
            {
                const currentAccount = event.payload.account || {};
                const { name = null, username: email = null } = currentAccount;

                instance.setActiveAccount(event.payload.account);
                handleLocalLogin(name, email);
            }
            else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) 
            {
                setIsLoading(false);
                setAlertError("ACQUIRE_TOKEN_FAILURE");
            }
            else if (event.eventType === EventType.LOGIN_FAILURE) 
            {
                setIsLoading(false);
                setAlertError("LOGIN_FAILURE");
            }
        });

        return () => 
        {
            if (callbackId) 
            {
                instance.removeEventCallback(callbackId);
            }
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [instance]);

    useEffect(() =>
    {
        const handleNavigationTiming = () => 
        {
            const perfEntries = performance.getEntriesByType('navigation');
            
            if (perfEntries.length > 0) 
            {
                const navigationEntry = perfEntries[0];
                
                if (navigationEntry.type === 'reload') 
                {
                    localStorage.removeItem("IsLoggedOut");
                    setIsLoggedOut(false);
                }
            }
        }
        
        window.addEventListener('load', handleNavigationTiming);

        if (isLoggedOut)
        {
            setTimeout(() =>
            {
                setIsLoggedOut(false);
            }, 3000);
        }
        
        return () => 
        {
            window.removeEventListener('load', handleNavigationTiming);
        }
    }, [isLoggedOut]);

    const handleLocalLogin = async (name, email) =>
    {
        setIsLoading(true);
        setAlertError(null);

        await axios({
            method: "post",
            url: "/api/authenticate_user",
            headers: { 'Content-Type': 'application/json' },
            data: { 
                username: name, 
                email: email
            }
        })
        .then((response) => 
        {
            setIsLoading(false);
            const responseData = response?.data || null;

            if (response.status === 200)
            {
                if (responseData.login)
                {
                    localStorage.removeItem("loginFailure");
                    dispatch(setRole(name, "Administrator", null));
                    setCurrentUser({ user: name, role: "Administrator" });
                    navigate(state?.path || "/");
                }
                else
                {
                    localStorage.clear();
                    localStorage.setItem("loginFailure", responseData?.data);
                    window.location.reload();
                }
            }
            else
            {
                console.log("Login Api: ", responseData);
                localStorage.clear();
                localStorage.setItem("loginFailure", "There is a problem in the server. Please contact site admin.");
                window.location.reload();
            }
        })
        .catch((error) => 
        {
            console.log("Login Api: ", error);
            setIsLoading(false);
            localStorage.clear();
            localStorage.setItem("loginFailure", "There is a problem in the server. Please contact site admin.");
            window.location.reload();
        });
    }  

    const handleLogin = async () => 
    {
        setIsLoading(true);
        setAlertError(null);
        
        if ((inProgress === InteractionStatus.None) && !isAuthenticated && accounts.length === 0)
        {
            await instance.loginRedirect(
            {
                scopes: ['user.read']
            });
        }
        else if (isAuthenticated && accounts.length > 0)
        {
            const { name = null, username: email = null } = accounts[0];
            handleLocalLogin(name, email);
        }
    }  

    const handleSubmit = async (event) => 
    {
        event.preventDefault();

        if (action === "Simple Log-In")
        {
            setIsLoading(true);
            setAlertError(null);
            setIsLoggedOut(false);

            const username = event.target.elements[0].value;
            const password = event.target.elements[1].value;

            await axios({
                method: "post",
                url: "/api/authenticate_user",
                headers: { 'Content-Type': 'application/json' },
                data: { username: username, password: password }
            })
            .then((response) => 
            {
                setIsLoading(false);

                if (response.status === 200)
                {
                    const responseData = response.data;

                    if (responseData.login)
                    {
                        dispatch(setRole(username, "Administrator", null));
                        setCurrentUser({ user: username, role: "Administrator" });
                        navigate(state?.path || "/");
                    }
                    else
                    {
                        setAlertError(responseData.data);
                    }
                }
            })
            .catch((error) => 
            {
                console.log("Login Api: ", error);
                setIsLoading(false);
                setAlertError("There is a problem in the server. Please contact site admin.");
            });
        }
        else if (action === "Azure Log-In")
        {
            handleLogin();
        }
    }

    return (
        <>
            <AuthenticatedTemplate>
                <div className = "login redirect-container">
                    <img src = {Logo} alt = "Clorox" />

                    <div className = "redirect-message text-center">
                        <div className = "bouncing-loader my-2">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <h2 className = "mt-0">Logging you in</h2>
                        <h5 className = "m-0">Please wait while you are redirected.</h5>
                    </div>
                </div>

                {currentUser?.user && (
                    <Navigate to = {state?.path || "/"} replace = {true} />
                )}
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <div className = "loginContainer">
                    <div className = "row">
                        <div className = "col-md-7">
                            <div className = "login">
                                <img src = {Logo} alt = "Clorox" />
                                <br />
                                
                                {isLoggedOut && (
                                    <Alert variant = "success">Logged Out Successfully.</Alert>
                                )}
                                
                                <h1>Login to Your Account</h1>
                                <br />

                                {isLoading && (
                                    <div className = "ui active dimmer Loader">
                                        <div className = "ui loader"></div>
                                    </div>
                                )}

                                {alertError && (
                                    <Alert variant = "danger">{alertError}</Alert>
                                )}

                                <form onSubmit = {handleSubmit}>
                                    <div className = "form-group">
                                        <input type = "text" name = "username" className = "form-control" id = "username" placeholder = "Username" required = {action === "Simple Log-In"} />
                                    </div>
                                    <div className = "form-group password-container">
                                        <input type = "password" name = "password" className = "form-control" id = "password" placeholder = "Password" required = {action === "Simple Log-In"} />
                                    </div>

                                    <div className = "d-flex justify-content-between align-items-center reset-password">
                                        <span></span>
                                        <Link to = "/login/reset-password">Reset Password</Link>
                                    </div>
                                    
                                    <br />
                                    <Button 
                                        type = "submit" 
                                        variant = "primary" 
                                        className = "login-button log-in"
                                        onClick = {() => setAction("Simple Log-In")}
                                    >
                                        Log In
                                    </Button>

                                    <div className = "my-3 d-flex align-items-center gap-2 justify-content-center">
                                        <hr className = "w-full" />
                                        <span>OR</span>
                                        <hr className = "w-full" />
                                    </div>

                                    <Button 
                                        type = "submit" 
                                        variant = "primary" 
                                        className = "login-button azure log-in"
                                        onClick = {() => setAction("Azure Log-In")}
                                    >
                                        Login with Azure AD
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </UnauthenticatedTemplate>
        </>
    );
}

const mapStateToProps = state => 
{
  return {
    userRole: state.userRole
  };
};

export default connect(mapStateToProps)(Login);